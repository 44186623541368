import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState } from "react";
import { GetAuthData, GetGmedInfo, GetInformationOrigin, GetInformationOriginNone, GetTelecomList } from "Datas/swr";
import { Arrow_Bottom, PrdKT_IC, PrdLG_IC, PrdSKT_IC } from "assets";
import { Block, SlowMotionVideo } from "@material-ui/icons";
import { textAlign } from "@mui/system";
import { yellow } from "@material-ui/core/colors";
import { Grow } from "@material-ui/core";

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  width: calc(100% / 3 - 16px - 0.01px);
  /* margin-left: calc(16px / 2); */
  margin-right: calc(72px / 3);
  align-items: center;
  border-radius: 20px;
  :nth-child(3n) {
    margin-right: 0px;
  }
  -webkit-box-shadow: 5px 5px 15px 5px #eee;
  box-shadow: 5px 5px 15px 5px #eee;
  @media (max-width: 1300px) {
    width: 100%;
    margin: -20px 0 50px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  width: calc(100% / 3 - 16px - 0.01px);
  /* margin-left: calc(16px / 2); */
  margin-right: calc(72px / 3);
  align-items: center;
  border-radius: 20px;
  :nth-child(3n) {
    margin-right: 0px;
  }
  -webkit-box-shadow: 5px 5px 15px 5px #eee;
  box-shadow: 5px 5px 15px 5px #eee;
  @media (max-width: 1300px) {
    width: 100%;
    margin: -20px 0px 50px;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

export const PhoneImgPare = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const PhoneImg = styled.img`
  width: 100%;
  height: 200px;
  max-width: 300px;
  object-fit: contain;
  resize: cover;
  @media screen and (max-width: 1300px) {
    min-width: 90px;
    max-width: 130px;
  }
  @media screen and (max-width: 1050px) {
    min-width: 80px;
  }
  @media screen and (max-width: 400px) {
    min-width: 70px;
    margin-left: -10px;
  }
  /* @media screen and (max-width: 768px) {
    width: 300px;
    height: 300px;
  } */
  /* @media screen and (max-width: 509px) {
    width: 190px;
    height: 190px;
  } */
`;

const PhoneInfo_T = styled.div`
  display: none;
  padding-bottom: 16px;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
    margin-bottom: 16px;
  }
`;

//휴대폰 상품
const PhoneInfo = styled.div`
  max-width: 100%;
  display: flex;
`;

const PhoneNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PhoneName = styled.div`
  font-size: 30px;
  font-weight: 700;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 450px) {
    font-size: 10px;
  }
`;

const Phone5G = styled.div`
  margin-top: 5px;
  display: flex;
  //margin-left: auto;
  margin-right: auto;
  background: #fff;
  font-weight: 700;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  //padding: 5px 11px;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  @media screen and (max-width: 450px) {
    font-size: 10px;
  }
`;

const PhoneRatePlan = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 17px;
`;

export const DisWrapper = styled.div`
  margin-bottom: 0px;
`;

const PhoneRate = styled.div`
  :hover {
    color: red;
    width: 100%;
  }
  display: flex;
  margin: 1px 0;
  align-items: center;
  @media screen and (max-width: 450px) {
    padding: 0px 10px;
  }
`;

export const PrdImgBox = styled.div`
  resize: contain;
`;

export const PrdIc_Img = styled.img`
  width: ${(props) => {
    if (props.skt === true) {
      return "37px";
    }
    if (props.lgu === true) {
      return "40px";
    }
    if (props.kt === true) {
      return "16px";
    }
  }};
  height: ${(props) => {
    if (props.skt === true) {
      return "16px";
    }
    if (props.lgu === true) {
      return "12px";
    }
    if (props.kt === true) {
      return "12px";
    }
  }};
  resize: contain;
`;

const RateName = styled.div`
  font-size: 10px;
  letter-spacing: -0.5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div.firstDiv {
    min-width: 35px;
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 0px;
  }
  @media screen and (max-width: 388px) {
    font-size: 8px;
  }
`;

const RateLeftName = styled.div`
  font-size: 12px;
  letter-spacing: -0.5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 450px) {
    margin-bottom: -5px;
  }
  @media screen and (max-width: 388px) {
    font-size: 11px;
  }
`;

const PhonePrice = styled.div`
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
  display: inline;

  @media screen and (max-width: 768px) {
    font-size: 11px;
    min-width: 72px;
  }
  /* @media screen and (max-width: 388px) {
    font-size: 12px;
  }
  @media screen and (max-width: 450px) {
    font-size: 10px;
  } */
`;

const RateFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin-left: 12px;
  @media screen and (max-width: 450px) {
    width: 88%;
  }
`;

const RateDiscount = styled.div`
  font-size: 13px;
  height: 20px;
color:red;
  font-weight: bold;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const ProductBox_T_Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  height: 100%;
  border-radius: 9px 0px 0px 0px;
  border-right: 1px solid #cecece;
`;

const ProductBox_T_inner_Right = styled.div`
  padding-left: 6px;
  padding-right: 6px;

  @media screen and (max-width: 450px) {
  }
`;

const ProductBox_T_Right = styled.div`
  height: 100%;

  width: 65%;
  margin-top: 5px;
  @media screen and (max-width: 451px) {
    margin-top: 0px;
  }

  @media screen and (max-width: 450px) {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

//전체박스
const ProductBox_T = styled.div`
  width: 100%;
  display: flex;
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 450px) {
  }
`;

const TitleHeadingText = styled.div`
  width: 100%;
  font-size: 15.3px;
  font-weight: 600;
  height: 50px;
  text-align: center;
  margin-top: ${(props) => {
    if (props.newOption !== false) {
      return "20px";
    }
    return;
  }};

  @media screen and (max-width: 1300px) {
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
    letter-spacing: -0.5px;
  }
  @media screen and (max-width: 400px) {
    font-size: 15px;
    margin-left: -5px;
  }
`;

const HeadingText = styled.div`
  width: fit-content;
  font-size: 16px;
  font-weight: 600;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 450px) {
    font-size: 10px;
  }
`;
const TextCompPare = styled.div`
  height: 20px; 
  border: 1px solid #999;

  display: flex;
  padding: 0px 18px;
  padding-top:2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f3f3;
  @media screen and (max-width: 768px) {
    height: 30px;
    margin: 0px 3px;
    padding: 0px;
    padding-left: 10px;
  }
  /* @media screen and (max-width: 450px) {
    height: 30px;
    margin: 0px 3px;
    padding-left: 10px;
  } */
`;
const TextComp = styled.text`
  font-size: 10px;
  @media screen and (max-width: 768px) {
    font-size: 9px;
    padding: 2px 0 0 2px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dddddd;
  margin: 10px 0;
`;

const ReleaseAndBuyPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ReleasePricePare = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #000;
  border-radius: 0px 0px 10px 10px;
  padding: 5px 0px;
`;
const ReleasePriceWrapper = styled.div`
  display: flex;
  margin-right: 29px;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
  }
`;

const ReleasePriceText = styled.div`
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-right: 20px;
  @media screen and (max-width: 768px) {
    font-size: 10px;
    margin-right: 0px;
    padding-left: 10px;
  }
`;

const ReleasePrice = styled.div`
  text-decoration: line-through;
  margin-left: auto;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    font-size: 10px;
    padding-right: 2px;
    margin-left: 2px;
  }
`;

const BuyPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BuyPriceText = styled.div`
  font-size: 20px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 388px) {
    font-size: 9px;
  }
`;

const BuyPrice = styled.div`
  margin-left: auto;
  font-size: 25px;
  font-weight: 700;
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
  @media screen and (max-width: 388px) {
    font-size: 10px;
  }
`;
const ProductBox = styled.div``;

//모바일 휴대폰 왼쪽 이미지
const LeftPhoneImgBox = styled.div`
  height: 300px;

  max-width: 450px;

  @media screen and (max-width: 768px) {
    max-width: 200px;
    height: 200px;
  }
  @media screen and (max-width: 450px) {
    height: 200px;
  }
  @media screen and (max-width: 450px) {
    height: 180px;
  }
`;
const ArrowIcon = styled.div`
  background-image: ${(props) => {
    if (props.url) {
      return `url(${props.url})`;
    }
  }};
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  object-fit: contain;
  background-size: 70%;
`;

const ContentsPare = styled.div`
  display: flex;
  width: 100%;
  height: 400px;
  position: relative;
  align-items: center;
  @media screen and (max-width: 760px) {
    /* height: 415px; */
    height: 500px;
  }
`;

const LeftContentPare = styled.div`
  padding-bottom: 4px;
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 25px;
  border-right: 1px solid #cecece;
  width: 45%;
  height: 100%;
`;

const SideText = styled.p`
  color: #fff;
  font-size: 11px;
  font-weight: bold;
`;
const SideBar = styled.div`
  background-color: #1ba0eb;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  position: absolute;
  letter-spacing: 0;
  top: 0px;
  left: 0px;

  border-radius: 10px 0px;
`;
const Left1920Contents = styled.div`
  display: flex;
  padding: 0 20px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  @media (max-width: 550px) {
    padding: 0 8px 0 20px;
    & > div {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
`;

const Right1920Contents = styled.div`
  display: flex;
  height: 100%;
  width: 55%;
  padding: 0px 10px;
 
  @media screen and (max-width: 450px) {
    padding: 0px 0px;
  }
`;



const Prd2 = (data, index, SKT선택, KT선택, LGU선택, OriginData, OriginNData, 공시정보, phonePrice, telecomPrice) => {

}
const Prd1 = (data, index, SKT선택, KT선택, LGU선택, OriginData, OriginNData, 공시정보, phonePrice, telecomPrice) => {
  function calculatePrice(type, phonePrice, discount1, discount2) {
    if (type === "공시") {
      return {
        transferPrice: phonePrice - discount1,
        transferPrice2: phonePrice - discount1 - discount2,
        transferType: "공시",
      };
    } else {
      return {
        transferPrice: phonePrice - discount1,
        transferPrice2: phonePrice - discount1 - discount2,
        transferType: "선택",
      };
    }
  }


  let tempItem = {
    id: data?.telecom_product_key_product[index]?.id,
    telecomName: data?.telecom_product_key_product[index]?.telecomname, // 통신사명
    transferPrice: "", // 기기변경 최종 비교 후 산출된 가격
    transferType: "", // 기기변경 할인 타입
    numberTransPrice: "", // 번호이동 최종 비교 후 산출된 가격
    numberTransType: "", // 번호이동 할인 타입
  };


  //전환지원
  const ChangeSaler_pr = data?.telecom_product_key_product[index]?.telecom_product.change_sales_price;


  // 공시지원

  const publicDiscount = Number(
    data?.telecom_product_key_product[index]?.telecom_product
      ?.dc_sales_price
  ); // 공시지원 할인금액

  let publicTransferFee = Number(
    data?.telecom_product_key_product[index]?.telecom_product
      ?.dc_sales_change_price
  ); // 공시지원 기기변경 가격
  if (true) {
    if (OriginData?.DcTrans) {
      publicTransferFee = 0;
    }
  } else {
    if (OriginNData?.DcTrans) {
      publicTransferFee = 0;
    }

  }
  //할부원금  출고가 - 슈퍼 DC - 공시지원
  let publicNumberTransFee = Number(
    data?.telecom_product_key_product[index]?.telecom_product
      ?.dc_sales_mnp_price
  ); // 공시지원 번호이동 가격


  if (true) {
    if (OriginData?.DcTrans) {
      publicNumberTransFee = 0;
    }
  } else {
    if (OriginNData?.DcTrans) {
      publicNumberTransFee = 0;
    }

  }




  const newOptionPrice = Number(
    data?.telecom_product_key_product[index]?.telecom_product
      ?.dc_sales_new_price
  ); //공시지원 신규가격
  const newOptionChoosePrice = Number(
    data?.telecom_product_key_product[index]?.telecom_product
      ?.dc_choose_new_price
  ); //선택약정 신규가격
  //신규가입 가격  출고가 - 공시/선택 옵션 가격 - 신규지원가격
  //요금제명
  const teleInfo = data?.telecom_product_key_product[index]?.name;
  const totalPublicTransferDiscount = publicDiscount + publicTransferFee; // 공시지원 기기변경 할인금액 최종 산출 가격

  const totalPublicNumberTransDiscount =
    publicDiscount + publicNumberTransFee; // 공시지원 번호이동 할인금액 최종 산출 가격

  // 선택약정
  const telecomPercentage =
    data?.telecom_product_key_product[index]?.telecom_product
      ?.dc_chooses_price; // 선택약정 할인 비율 --> %로 나옴

  const telecomDiscount =
    data?.telecom_product_key_product[index]?.pricemonth *
    data?.telecom_product_key_product[index]?.telecom_product
      ?.dc_chooses_price *
    0.24; // 선택약정 할인금액

  let telecomTransferFee = Number(
    data?.telecom_product_key_product[index]?.telecom_product
      ?.dc_choose_change_price
  ); // 선택약정 기기변경 가격

  //선택약정 신규가격
  if (true) {
    if (OriginData?.DcTrans) {
      telecomTransferFee = 0;
    }
  } else {
    if (OriginNData?.DcTrans) {
      telecomTransferFee = 0;
    }
  }

  let telecomNumberTransFee = Number(
    data?.telecom_product_key_product[index]?.telecom_product
      ?.dc_choose_mnp_price
  ); // 선택약정 번호이동 가격
  if (true) {

    if (OriginData?.DcTrans) {
      telecomNumberTransFee = 0;
    }
  } else {
    if (OriginNData?.DcTrans) {
      telecomNumberTransFee = 0;
    }
  }
  const totalTelecomTransferDiscount = telecomTransferFee; // 선택약정 기기변경 할인금액 최종 산출 가격

  const totalTelecomNumberTransDiscount =
    telecomDiscount - telecomNumberTransFee; // 선택약정 기기변경 할인금액 최종 산출 가격

  // 공시지원 할인금액 & 선택약정 할인금액 비교
  if (data.telecom_product_key_product[index].telecom_product.opt === true && 공시정보 === "할인유형별") {
    if (data.telecom_product_key_product[index]?.telecomname === "KT") {
      phonePrice = data?.KTPrice;
    } else if (
      data.telecom_product_key_product[index]?.telecomname === "SKT"
    ) {
      phonePrice = data?.SKTPrice;
    } else if (
      data.telecom_product_key_product[index]?.telecomname === "LGU+"
    ) {
      phonePrice = data?.LGUPrice;
    }
    tempItem = {
      ...tempItem,
      transferPrice: phonePrice - totalTelecomTransferDiscount,
      transferPrice2:
        phonePrice - totalTelecomTransferDiscount - newOptionChoosePrice,
      transferType: "선택",
      teleInfo: teleInfo,
      ChangeSaler_pr: ChangeSaler_pr,
      phonePrices: phonePrice,
    };
  } else if (data.telecom_product_key_product[index].telecom_product.opt === false && 공시정보 === "할인유형별") {
    if (data.telecom_product_key_product[index]?.telecomname === "KT") {
      phonePrice = data?.KTPrice;
    } else if (
      data.telecom_product_key_product[index]?.telecomname === "SKT"
    ) {
      phonePrice = data?.SKTPrice;
    } else if (
      data.telecom_product_key_product[index]?.telecomname === "LGU+"
    ) {
      phonePrice = data?.LGUPrice;
    }

    tempItem = {
      ...tempItem,
      transferPrice: phonePrice - totalPublicTransferDiscount,
      transferPrice2:
        phonePrice - totalPublicTransferDiscount - newOptionPrice,
      transferType: "공시",
      ChangeSaler_pr: ChangeSaler_pr,
      teleInfo: teleInfo,
      phonePrices: phonePrice,
    };
  }
  else if (
    공시정보 === "공시지원할인"
  ) {
    if (data.telecom_product_key_product[index]?.telecomname === "KT") {
      phonePrice = data?.KTPrice;
    } else if (
      data.telecom_product_key_product[index]?.telecomname === "SKT"
    ) {
      phonePrice = data?.SKTPrice;
    } else if (
      data.telecom_product_key_product[index]?.telecomname === "LGU+"
    ) {
      phonePrice = data?.LGUPrice;
    }

    tempItem = {
      ...tempItem,
      transferPrice: phonePrice - totalPublicTransferDiscount,
      transferPrice2:
        phonePrice - totalPublicTransferDiscount - newOptionPrice,
      transferType: "공시",
      ChangeSaler_pr: ChangeSaler_pr,
      teleInfo: teleInfo,
      phonePrices: phonePrice,
    };
  } else {
    if (data.telecom_product_key_product[index]?.telecomname === "KT") {
      phonePrice = data?.KTPrice;
    } else if (
      data.telecom_product_key_product[index]?.telecomname === "SKT"
    ) {
      phonePrice = data?.SKTPrice;
    } else if (
      data.telecom_product_key_product[index]?.telecomname === "LGU+"
    ) {
      phonePrice = data?.LGUPrice;
    }
    tempItem = {
      ...tempItem,
      transferPrice: phonePrice - totalTelecomTransferDiscount,
      transferPrice2:
        phonePrice - totalTelecomTransferDiscount - newOptionChoosePrice,
      transferType: "선택",
      teleInfo: teleInfo,
      ChangeSaler_pr: ChangeSaler_pr,
      phonePrices: phonePrice,
    };
  }

  if (data.telecom_product_key_product[index].telecom_product.opt === true && 공시정보 === "할인유형별") {
    tempItem = {
      ...tempItem,
      numberTransPrice: phonePrice - telecomNumberTransFee,
      numberTransPrice2:
        phonePrice - telecomNumberTransFee - newOptionChoosePrice,
      numberTransType: "선택",
      teleInfo: teleInfo,
      ChangeSaler_pr: ChangeSaler_pr,
      phonePrices: phonePrice,
    };
  } else if (data.telecom_product_key_product[index].telecom_product.opt === false && 공시정보 === "할인유형별") {
    tempItem = {
      ...tempItem,
      numberTransPrice: phonePrice - totalPublicNumberTransDiscount,
      numberTransPrice2:
        phonePrice - totalPublicNumberTransDiscount - newOptionPrice,
      numberTransType: "공시",
      ChangeSaler_pr: ChangeSaler_pr,
      teleInfo: teleInfo,
      phonePrices: phonePrice,
    };
  }
  else if (
    공시정보 === "공시지원할인"
  ) {
    tempItem = {
      ...tempItem,
      numberTransPrice: phonePrice - totalPublicNumberTransDiscount,
      numberTransPrice2:
        phonePrice - totalPublicNumberTransDiscount - newOptionPrice,
      numberTransType: "공시",
      ChangeSaler_pr: ChangeSaler_pr,
      teleInfo: teleInfo,
      phonePrices: phonePrice,
    };
  } else {

    tempItem = {
      ...tempItem,
      numberTransPrice: phonePrice - telecomNumberTransFee,
      numberTransPrice2:
        phonePrice - telecomNumberTransFee - newOptionChoosePrice,
      numberTransType: "선택",
      teleInfo: teleInfo,
      ChangeSaler_pr: ChangeSaler_pr,
      phonePrices: phonePrice,
    };
  }

  telecomPrice.push(tempItem);

}
const Phone = ({ data, 공시정보, SKT선택, LGU선택, KT선택 }) => {
  const [toggle, setToggle] = useState(true);
  const [renderData, setRenderData] = useState();
  let phonePrice = data?.marketprice;
  const ConsumePrice = Number(phonePrice);
  //가격처리시 마무리 
  const { GmedInfoData, GmedInfoMutate } = GetGmedInfo();
  const { OriginData, OriginMutate } = GetInformationOrigin();
  const { AuthData } = GetAuthData();
  const { OriginNData, OriginNError, OriginNMutate } = GetInformationOriginNone();

  const handlePrice = () => {
    let telecomPrice = [];
    // 출고가격

    console.log(data, '데이터')
    for (
      let index = 0;
      index < data?.telecom_product_key_product?.length;
      index++
    ) {

      if (data?.telecom_product_key_product[index]?.telecomname === 'SKT' && SKT선택 !== "SKT요금제") {

        if (data?.telecom_product_key_product[index]?.id === SKT선택) {
          Prd1(data, index, SKT선택, KT선택, LGU선택, OriginData, OriginNData, 공시정보, phonePrice, telecomPrice);

        }
      } else if (data?.telecom_product_key_product[index]?.telecomname === 'KT' && KT선택 !== "KT요금제") {
        if (data?.telecom_product_key_product[index]?.id === KT선택) {
          Prd1(data, index, SKT선택, KT선택, LGU선택, OriginData, OriginNData, 공시정보, phonePrice, telecomPrice);
        }
      } else if (data?.telecom_product_key_product[index]?.telecomname === 'LGU+' && LGU선택 !== "LGU+요금제") {
        if (data?.telecom_product_key_product[index]?.id === LGU선택) {
          Prd1(data, index, SKT선택, KT선택, LGU선택, OriginData, OriginNData, 공시정보, phonePrice, telecomPrice);
        }
      } else if (data?.telecom_product_key_product[index]?.telecom_product?.validate) {  //통신사별 처리해야함 
        Prd1(data, index, SKT선택, KT선택, LGU선택, OriginData, OriginNData, 공시정보, phonePrice, telecomPrice);
      }


    }


    const newTelP = telecomPrice.sort((a, b) => {
      if (a.telecomName === "SKT") {
        return -1;
      }
      if (b.telecomName === "KT") {
        return 0;
      }
      if (b.telecomName === "LGU+") {
        return -1;
      }
      return 0;
    });

    setRenderData({
      phonePrice: phonePrice, // 출고가격
      telecomPrice: newTelP,
    });
  };

  useEffect(() => handlePrice(), [data, 공시정보, SKT선택, KT선택, LGU선택]);

  return (
    <Wrapper>
      {toggle ? (
        <>
          {/* 1920px  1440px */}
          <PhoneInfo
            style={{
              width: "100%",
              height: "100%",
              flexDirection: "column",
              border: "1px solid #cecece",
              borderRadius: "10px",
            }}
          >
            {/* 감싸기 */}
            <ContentsPare>
              {/* 왼쪽 */}
              <LeftContentPare>
                {data?.newOption && (
                  <SideBar>
                    <SideText>신규</SideText>
                  </SideBar>
                )}

                <Left1920Contents>
                  <TitleHeadingText newOption={data?.newOption}>
                    {data.name}
                  </TitleHeadingText>
                  <PhoneImgPare>
                    <PhoneImg
                      alt=""
                      src={data?.images}
                      style={{ borderRadius: 7 }}
                    ></PhoneImg>
                  </PhoneImgPare>
                  {/* 화면크기 내장메모리 배터리 크기 */}
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      paddingBottom: 4,
                      paddingTop: 2,
                      justifyContent: "space-between",
                    }}
                  >
                    <RateLeftName
                      style={{
                        paddingTop: 5,
                        display: "flex",
                        width: "auto",
                        fontWeight: "bold",
                      }}
                    >
                      화면크기
                    </RateLeftName>
                    <RateLeftName
                      style={{
                        paddingTop: 5,
                        width: "auto",
                        boxShadow: "none",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {data?.windowSize}
                    </RateLeftName>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      paddingBottom: 4,
                      paddingTop: 2,
                    }}
                  >
                    <RateLeftName
                      style={{
                        paddingTop: 5,
                        width: "auto",
                        fontWeight: "bold",
                      }}
                    >
                      내장메모리
                    </RateLeftName>
                    <RateLeftName
                      style={{
                        paddingTop: 5,
                        boxShadow: "none",
                        color: "red",
                        width: "auto",
                        fontWeight: "bold",
                        display: "flex",
                      }}
                    >
                      {data?.memorySize}
                    </RateLeftName>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      paddingBottom: 4,
                      paddingTop: 2,
                      justifyContent: "space-between",
                    }}
                  >
                    <RateLeftName
                      style={{
                        width: "auto",
                        paddingTop: 5,
                        boxShadow: "none",
                        fontWeight: "bold",
                      }}
                    >
                      배터리
                    </RateLeftName>
                    <RateLeftName
                      style={{
                        paddingTop: 5,
                        width: "auto",
                        boxShadow: "none",
                        color: "red",
                        fontWeight: "bold",
                      }}
                    >
                      {data?.ramSize}
                    </RateLeftName>
                  </div>
                </Left1920Contents>
              </LeftContentPare>

              <Right1920Contents>
                <PhoneRatePlan>
                  {renderData?.telecomPrice?.map((item, idx) => {
                    let contrastPrice;
                    let countPrice;

                    if (
                      Number(item?.transferPrice) <=
                      Number(item?.numberTransPrice)
                    ) {
                      countPrice = item?.transferPrice;
                      contrastPrice = 1; // 1번이 더싼값
                    } else {
                      //넘버트랜스가 더싼경우
                      countPrice = item?.numberTransPrice;
                      contrastPrice = 2; // 2번이 더싼값
                    }

                    let TongPrice;
                    let Info;
                    let oned = false;
                    if (item?.telecomName === "SKT") {
                      if (true) {
                        TongPrice = OriginData?.sktPrice;
                      } else {
                        TongPrice = OriginNData?.sktPrice;

                      }
                      Info = data?.sktinfo;
                      if (data.sktOn === true) {
                        oned = true;
                      }
                    }
                    if (item?.telecomName === "KT") {
                      if (true) {
                        TongPrice = OriginData?.ktPrice;
                      }
                      else {
                        TongPrice = OriginNData?.ktPrice;
                      }
                      Info = data?.ktinfo;
                      if (data.ktOn === true) {
                        oned = true;
                      }
                    }
                    if (item?.telecomName === "LGU+") {
                      if (true) {
                        TongPrice = OriginData?.lguPrice;
                      }
                      else {
                        TongPrice = OriginNData?.lguPrice;
                      }
                      Info = data?.lguinfo;
                      if (data.lguOn === true) {
                        oned = true;
                      }
                    }
                    if (oned === true) {
                      return <></>;
                    }
                    return (
                      <>
                        <RateFlexBox>
                          {oned === true ? (
                            <PrdImgBox>
                              {item?.telecomName === "SKT" && (
                                <PrdIc_Img
                                  skt={true}
                                  src={PrdSKT_IC}
                                ></PrdIc_Img>
                              )}

                              {item?.telecomName === "LGU" && (
                                <PrdIc_Img
                                  lgu={true}
                                  src={PrdLG_IC}
                                ></PrdIc_Img>
                              )}

                              {item?.telecomName === "KT" && (
                                <PrdIc_Img kt={true} src={PrdKT_IC}></PrdIc_Img>
                              )}
                            </PrdImgBox>
                          ) : (
                            <PrdImgBox>
                              {item?.telecomName === "SKT" && (
                                <PrdIc_Img
                                  skt={true}
                                  src={PrdSKT_IC}
                                ></PrdIc_Img>
                              )}

                              {item?.telecomName === "LGU+" && (
                                <PrdIc_Img
                                  lgu={true}
                                  src={PrdLG_IC}
                                ></PrdIc_Img>
                              )}

                              {item?.telecomName === "KT" && (
                                <PrdIc_Img kt={true} src={PrdKT_IC}></PrdIc_Img>
                              )}
                            </PrdImgBox>
                          )}

                          {oned === false && (
                            <>
                              <RateDiscount style={{ marginLeft: 15 }}>
                                {(
                                  (100 * (item?.phonePrices - Number(countPrice))) /
                                  item?.phonePrices
                                ).toFixed(1)}
                                %
                                <ArrowIcon
                                  style={{ marginLeft: 3 }}
                                  url={Arrow_Bottom}
                                ></ArrowIcon>
                              </RateDiscount>
                            </>
                          )}
                        </RateFlexBox>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column" /* 
                            justifyContent: "center", 2022-12-25*/,
                          }}
                        >
                          <>
                            <TextCompPare>
                              <TextComp>{item?.teleInfo} 요금제</TextComp>
                            </TextCompPare>
                          </>

                          {oned === false && (
                            <>
                              <Link
                                to={{
                                  pathname: `/product/${data.id}/changePhone/${item.telecomName
                                    }/${item?.telecomName}/${item?.id}/${item?.transferType === "공시"
                                      ? "공시지원할인"
                                      : "선택약정할인"
                                    }`,
                                  state: {
                                    id: data.id, //상품아이디
                                    type: "changePhone",
                                    /* tel: item?.name, //선택 텔레콤네임
                            newtel: item?.name, //기기만 변경이기에 기존 텔레콤을상속
                            chooseBase: ValidateFilter.id, //선택요금제 데이터아이디 */
                                    tel: item?.telecomName,
                                    newtel: item?.telecomName,
                                    chooseBase: item?.id,
                                    discount:
                                      item?.transferType === "공시"
                                        ? "공시지원할인"
                                        : "선택약정할인",
                                    //프로덕트 텔레콤의 데이터 아이디를 넣어준다
                                  },
                                }}
                              >
                                <PhoneRate
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    marginTop: 5,
                                    padding: "0px 15px",
                                  }}
                                >
                                  <DisWrapper
                                    style={{
                                      display: "flex",
                                      width: "100%",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <RateName>
                                      <div>기기변경</div>
                                    </RateName>
                                    {oned === false && (
                                      <>
                                        {Info !== null && (
                                          <RateName
                                            style={{
                                              color: "red",
                                              fontWeight: "bold",
                                              display: "flex",
                                              justifyContent: "flex-end",
                                            }}
                                          >
                                            {Info}
                                          </RateName>
                                        )}
                                      </>
                                    )}
                                  </DisWrapper>
                                  <RateName>
                                    <PhonePrice
                                      style={{

                                        /* boxShadow: "inset 0 -3px 0 #d9fcdb",
                              color: contrastPrice ? "red" : "red", */
                                        color:
                                          contrastPrice === 1 ? "red" : "none",
                                      }}
                                    >
                                      {true ? <>
                                        {(item?.transferPrice -
                                          TongPrice +
                                          OriginData?.sale >
                                          0
                                          ? item?.transferPrice -
                                          TongPrice +
                                          OriginData?.sale
                                          : item?.transferPrice -
                                          TongPrice +
                                          OriginData?.sale
                                        )?.toLocaleString()}
                                      </> : <>
                                        {(item?.transferPrice -
                                          TongPrice +
                                          OriginNData?.sale >
                                          0
                                          ? item?.transferPrice -
                                          TongPrice +
                                          OriginNData?.sale
                                          : item?.transferPrice -
                                          TongPrice +
                                          OriginNData?.sale
                                        )?.toLocaleString()}
                                      </>}

                                      원
                                    </PhonePrice>
                                    {/* 첫번째 공시 */}
                                    <div
                                      className="firstDiv"
                                      style={
                                        item?.numberTransType === "공시"
                                          ? {
                                            color: "#1590E9",
                                            fontWeight: "bold",
                                            marginLeft: 6,
                                            border: "1px solid #1590E9",
                                            fontSize: 9,
                                            borderRadius: "4px",
                                            padding: "3px 7px",
                                          }
                                          : {
                                            color: "red",
                                            fontWeight: "bold",
                                            marginLeft: 6,
                                            border: "1px solid red",
                                            fontSize: 9,
                                            borderRadius: "4px",
                                            padding: "3px 7px",
                                          }
                                      }
                                    >
                                      {item?.transferType}
                                    </div>
                                  </RateName>
                                </PhoneRate>
                              </Link>
                              <Link
                                to={{
                                  pathname: `/product/${data.id}/changeNum/${item?.telecomName === "SKT" ? "LGU+" : "SKT"
                                    }/${item?.telecomName}/${item?.id}/${item?.transferType === "공시"
                                      ? "공시지원할인"
                                      : "선택약정할인"
                                    }`,
                                  state: {
                                    id: data.id,
                                    type: "changeNum",
                                    /* chooseBase: ValidateFilter.id, //선택요금제 데이터아이디
                            tel: item?.name === "SKT" ? "LGU+" : "SKT",
                            newtel: item?.name, */
                                    chooseBase: item?.id,
                                    tel:
                                      item?.telecomName === "SKT"
                                        ? "LGU+"
                                        : "SKT",
                                    newtel: item?.telecomName,
                                    discount:
                                      item?.transferType === "공시"
                                        ? "공시지원할인"
                                        : "선택약정할인",
                                  },
                                }}
                              >
                                <PhoneRate
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    padding: "0px 15px",
                                  }}
                                >
                                  <RateName>
                                    <div>번호이동</div>
                                  </RateName>
                                  <RateName>
                                    <PhonePrice
                                      style={{
                                        /* boxShadow: "inset 0 -3px 0 #d9fcdb",
                              color: contrastPrice ? "red" : "red", */
                                        color:
                                          contrastPrice === 2 ? "red" : "none",
                                      }}
                                    >
                                      {true ? <>
                                        {(item?.numberTransPrice -
                                          TongPrice +
                                          OriginData?.sale >
                                          0
                                          ? item?.numberTransPrice -
                                          TongPrice +
                                          OriginData?.sale - item?.ChangeSaler_pr
                                          : item?.numberTransPrice -
                                          TongPrice +
                                          OriginData?.sale - item?.ChangeSaler_pr
                                        )?.toLocaleString()}
                                      </> : <>
                                        {(item?.numberTransPrice -
                                          TongPrice +
                                          OriginNData?.sale >
                                          0
                                          ? item?.numberTransPrice -
                                          TongPrice +
                                          OriginNData?.sale - item?.ChangeSaler_pr
                                          : item?.numberTransPrice -
                                          TongPrice +
                                          OriginNData?.sale - item?.ChangeSaler_pr
                                        )?.toLocaleString()}
                                      </>}

                                      원
                                    </PhonePrice>
                                    <div
                                      className="firstDiv"
                                      style={
                                        item?.numberTransType === "공시"
                                          ? {
                                            color: "#1590E9",
                                            fontWeight: "bold",
                                            margin: "-8px 0px 10px 6px",
                                            border: "1px solid #1590E9",
                                            fontSize: 10,
                                            borderRadius: "4px",
                                            padding: "3px 7px",
                                          }
                                          : {
                                            color: "red",
                                            fontWeight: "bold",
                                            margin: "-10px 0 10px 6px",
                                            border: "1px solid red",
                                            fontSize: 10,
                                            borderRadius: "4px",
                                            padding: "3px 7px",
                                          }
                                      }
                                    >
                                      {item?.transferType}
                                    </div>
                                  </RateName>
                                </PhoneRate>
                              </Link>

                              {/* {data?.newOption && (
                              <Link
                                to={{
                                  pathname: `/product/${
                                    data.id
                                  }/changeNum/${"신규가입"}/${
                                    item?.telecomName
                                  }/${item?.id}/${
                                    item?.transferType === "공시"
                                      ? "공시지원할인"
                                      : "선택약정할인"
                                  }`,
                                  state: {
                                    id: data.id,
                                    type: "changeNum",

                                    chooseBase: item?.id,
                                    tel: "신규가입",
                                    newtel: item?.telecomName,
                                    discount:
                                      item?.transferType === "공시"
                                        ? "공시지원할인"
                                        : "선택약정할인",
                                  },
                                }}
                              >
                                <PhoneRate
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    marginTop: 5,
                                    padding: "0px 15px",
                                  }}
                                >
                                  <RateName>
                                    <div>신규가입</div>
                                  </RateName>
                                  <RateName>
                                    <PhonePrice
                                      style={{
                                        color:
                                          contrastPrice === 3 ? "red" : "none",
                                      }}
                                    >
                                      {(item?.numberTransPrice2 -
                                        TongPrice +
                                        OriginData?.sale >
                                      0
                                        ? item?.numberTransPrice2 -
                                          TongPrice +
                                          OriginData?.sale
                                        : 0
                                      )?.toLocaleString()}
                                      원
                                    </PhonePrice>
                                    <div
                                      style={
                                        item?.numberTransType === "공시"
                                          ? {
                                              color: "#1590E9",
                                              fontWeight: "bold",
                                              marginLeft: 6,
                                              border: "1px solid #1590E9",
                                              fontSize: 10,
                                              borderRadius: "4px",
                                              padding: "3px 7px",
                                            }
                                          : {
                                              color: "red",
                                              fontWeight: "bold",
                                              marginLeft: 6,
                                              border: "1px solid red",
                                              fontSize: 10,
                                              borderRadius: "4px",
                                              padding: "3px 7px",
                                            }
                                      }
                                    >
                                      {item?.transferType}
                                    </div>
                                  </RateName>
                                </PhoneRate>
                              </Link>
                            )} */}
                            </>
                          )}

                          {oned === true && (
                            <>
                              <PhoneRate
                                style={{
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  marginTop: 8,
                                }}
                              >
                                <RateName>
                                  <div>기기변경</div>
                                </RateName>
                                <RateName>
                                  <PhonePrice>
                                    {Info !== null && (
                                      <RateName
                                        style={{
                                          color: "red",
                                          paddingTop: 5,
                                          paddingBottom: 5,
                                        }}
                                      >
                                        {Info}
                                      </RateName>
                                    )}
                                  </PhonePrice>
                                </RateName>
                              </PhoneRate>

                              <PhoneRate
                                style={{
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <RateName>
                                  <div>번호이동</div>
                                </RateName>
                                <RateName>
                                  <PhonePrice>
                                    {Info !== null && (
                                      <RateName
                                        style={{
                                          color: "red",
                                          paddingTop: 5,
                                          paddingBottom: 5,
                                        }}
                                      >
                                        {Info}
                                      </RateName>
                                    )}
                                  </PhonePrice>
                                </RateName>
                              </PhoneRate>
                              {/* {data?.newOption && (
                              <Link
                                to={{
                                  pathname: `/product/${
                                    data.id
                                  }/changeNum/${"신규가입"}/${
                                    item?.telecomName
                                  }/${item?.id}/${
                                    item?.transferType === "공시"
                                      ? "공시지원할인"
                                      : "선택약정할인"
                                  }`,
                                  state: {
                                    id: data.id,
                                    type: "changeNum",

                                    chooseBase: item?.id,
                                    tel: "신규가입",
                                    newtel: item?.telecomName,
                                    discount:
                                      item?.transferType === "공시"
                                        ? "공시지원할인"
                                        : "선택약정할인",
                                  },
                                }}
                              >
                                <PhoneRate
                                  style={{
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                  }}
                                >
                                  <RateName>
                                    <div>신규가입</div>
                                  </RateName>
                                  <RateName>
                                    <PhonePrice
                                      style={{
                                        boxShadow: contrastPrice
                                          ? "inset 0 -4px 0 #F95858"
                                          : "none",
                                      }}
                                    >
                                      {(
                                        item?.numberTransPrice2 -
                                        TongPrice +
                                        OriginData?.sale
                                      )?.toLocaleString()}
                                      원
                                    </PhonePrice>
                                    <div
                                      style={
                                        item?.numberTransType === "공시"
                                          ? {
                                              color: "#33a0df",
                                              fontWeight: "bold",
                                              marginLeft: 6,
                                            }
                                          : {
                                              color: "red",
                                              fontWeight: "bold",
                                              marginLeft: 6,
                                            }
                                      }
                                    >
                                      {item?.transferType}
                                    </div>
                                  </RateName>
                                </PhoneRate>
                              </Link>
                            )} */}
                            </>
                          )}
                        </div>
                      </>
                    );
                  })}
                </PhoneRatePlan>
              </Right1920Contents>
            </ContentsPare>

            <ReleasePricePare>
              {renderData?.telecomPrice?.map((item, idx) => {
                let MarketPrice = 0;
                if (item?.telecomName === "SKT") {
                  MarketPrice = Number(data?.SKTPrice);
                }
                if (item?.telecomName === "KT") {
                  MarketPrice = Number(data?.KTPrice);
                }
                if (item?.telecomName === "LGU+") {
                  MarketPrice = Number(data?.LGUPrice);
                }
                return (
                  <ReleasePriceWrapper>
                    <ReleasePriceText
                      style={{
                        color: "#fff",
                        fontWeight: "600",
                        textAlign: "center",

                        height: "20px",
                      }}
                    >
                      {item?.telecomName}
                    </ReleasePriceText>
                    <ReleasePrice
                      style={{
                        textDecoration: "none",
                        color: "yellow",
                      }}
                    >
                      {MarketPrice.toString().replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )}
                      원
                    </ReleasePrice>
                  </ReleasePriceWrapper>
                );
              })}
              {/* <Line /> */}
            </ReleasePricePare>
          </PhoneInfo>
        </>
      ) : (
        <>
          <PhoneImg>
            <img alt="" src={data?.images} style={{ borderRadius: 7 }} />
          </PhoneImg>

          <PhoneInfo
            style={{
              width: "100%",
              padding: "0px 15px",
            }}
          >
            <PhoneInfo>
              <Phone5G> {data?.giga}</Phone5G>
              <PhoneNameWrapper>
                <PhoneName>{data?.name}</PhoneName>
              </PhoneNameWrapper>
            </PhoneInfo>
            <div
              onClick={() => setToggle(true)}
              className="buttonTag"
              style={{
                width: "100%",
                height: 40,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "orange",
                color: "#fff",
                margin: "10px 0px",
                fontSize: 13,
                fontWeight: "600",
                borderRadius: 100,
              }}
            >
              가격 보러가기
            </div>
          </PhoneInfo>
        </>
      )}
    </Wrapper>
  );
};

export default Phone;
